import { IStorages } from "./IStorages";

const dispatch = async (url: string, method: string, data?: any) => {
  const body = JSON.stringify(data);
  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    if (response.status === 200) {
      const data = await response.json();
      const { value, success } = data;
      return value ?? success;
    }
    return null;
  } catch (e) {
    throw new Error("Error while fetching data");
  }
};

const ServerStorageService = (): IStorages => {
  const BASE_URL = `${process.env.NEXT_PUBLIC_APP_URL}/api/storage`;
  return {
    save: async ({ hash, token }) => {
      try {
        const savedKey = await dispatch(BASE_URL, "POST", { hash, token });
        return savedKey as boolean;
      } catch (e) {
        return false;
      }
    },
    get: async (hash) => {
      try {
        const value = await dispatch(`${BASE_URL}?key=${hash}`, "GET");
        if (value) {
          return value;
        }
        return null;
      } catch {
        return null;
      }
    },
    remove: async (hash) => {
      try {
        const removedKey = await dispatch(`${BASE_URL}?key=${hash}`, "DELETE");
        if (removedKey) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
  };
};

let instance: IStorages | null = null;

const getInstance = () => {
  if (!instance) {
    instance = ServerStorageService();
  }
  return instance;
};
export default getInstance();
