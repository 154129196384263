import { css, styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

interface IContentProps {
  size: "small" | "large";
  hasBorderTop?: boolean;
}

export const Content: ComponentType<
  HTMLAttributes<HTMLDivElement> & IContentProps
> = styled("div")<IContentProps>`
  min-height: 14rem;

  ${(props) =>
    props.hasBorderTop &&
    css`
      padding-top: 1rem;
      border-top: 1px solid ${props.theme.customColors.gray[200]};
    `};

  @media (max-width: 767.98px) {
    min-height: 100%;
  }
`;
