import { COURSES_LMS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { toast } from "@/components/common/Toast";
import { useSessionProvider } from "./useSessionProvider";

export type CourseLms = {
  id: number;
  name: string;
  total_activated_quizzes: number;
  active: boolean;
  created_at: string;
};

interface GetCoursesLmsProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  sortBy?: string;
  sort?: string;
  published?: boolean;
  accountId?: number;
}

type GetCoursesLmsResponse = {
  courses: CourseLms[];
  nextPage: number | null;
  prevPage: number | null;
  currentPage: number | null;
  total: number;
  isAllCoursesEnabled: boolean;
};

interface UseCoursesLmsProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  published?: boolean;
  accountId?: number;
  onSuccess?: (data: GetCoursesLmsResponse) => void;
}

export function useCoursesLms({
  page,
  perPage,
  prevPage,
  search,
  published,
  sortBy,
  sort,
  enabled = true,
  accountId,
  onSuccess,
}: UseCoursesLmsProps) {
  const { api } = useApiClient();
  const { isD2LBrightspaceProvider } = useSessionProvider();

  const getCourses = async ({
    page,
    perPage,
    prevPage,
    search,
    published,
    sortBy,
    sort,
  }: GetCoursesLmsProps): Promise<GetCoursesLmsResponse> => {
    const params = {
      search: search || undefined,
      published,
      page: page === 0 ? "" : page || undefined,
      prev_page: undefined as string | undefined,
      paginates_per: perPage || undefined,
      sort: sort || undefined,
      sort_by: sortBy || undefined,
      account_id: accountId || undefined,
    };

    if (isD2LBrightspaceProvider) {
      params.prev_page = prevPage == 0 ? "" : String(prevPage || "");
    }

    const response = await api.get(COURSES_LMS_LIST_URL, {
      params,
    });

    const courses = response.data.content.courses;

    const parsePrevPage = isD2LBrightspaceProvider
      ? response.data.content.current_page
        ? response.data.content.prev_page || "0"
        : response.data.content.prev_page
      : response.data.content.prev_page;

    return {
      courses,
      prevPage: parsePrevPage,
      nextPage: response.data.content.next_page,
      currentPage: response.data.content.current_page,
      total: response.data.content.total_items || courses.length,
      isAllCoursesEnabled: response.data.content.all_courses_enabled,
    };
  };

  return useQuery({
    queryKey: [
      "courses_lms",
      {
        page,
        perPage,
        prevPage,
        search,
        published,
        sortBy,
        sort,
        accountId,
      },
    ],
    queryFn: () =>
      getCourses({
        page,
        perPage,
        prevPage,
        search,
        published,
        sortBy,
        sort,
        accountId,
      }),
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
    onSuccess,
  });
}
