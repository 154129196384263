export const PREFIX = "lti/v1/";
export const AUTH_PREFIX = "auth/v1/";

export const AUTH_SIGN_UP = `${AUTH_PREFIX}/authentications/signup`;
export const SETTINGS_TOTAL_STUDENTS_URL = `${PREFIX}settings/total_students`;
export const SETTINGS_DATA_URL = `${PREFIX}settings/get`;
export const QUIZZES_UPDATE_URL = `${PREFIX}quizzes/update`;
export const QUIZZES_URL = `${PREFIX}quizzes`;
export const QUIZZES_LIST_URL = `${PREFIX}quizzes/list`;
export const QUIZZES_LMS_LIST_URL = `${PREFIX}quizzes/list_lms`;
export const QUIZ_BY_ID_URL = `${PREFIX}quizzes/get_quiz`;
export const QUIZ_UPDATE_ACTIVE_URL = `${PREFIX}quizzes/active`;
export const QUIZ_DELETE_URL = `${PREFIX}quizzes/delete`;
export const QUIZZES_UPDATE_VISIBILITY_URL = `${PREFIX}quizzes/update_visibility`;
export const QUIZZES_SESSIONS_LIST_URL = `${PREFIX}sessions/list`;
export const QUIZZES_SESSIONS_DETAIL_URL = `${PREFIX}sessions/detail`;
export const QUIZZES_SESSIONS_UPDATE_URL = `${PREFIX}sessions/update`;
export const PROCTORING_SETTING_BY_ID_URL = `${PREFIX}proctoring_settings/get_proctoring_setting`;
export const PROCTORING_SETTING_CREATE_URL_URL = `${PREFIX}proctoring_settings/create_url`;
export const PROCTORING_SETTING_UPDATE_URL_URL = `${PREFIX}proctoring_settings/update_url`;
export const PROCTORING_SETTING_DELETE_URL_URL = `${PREFIX}proctoring_settings/delete_url`;
export const PROCTORING_SETTING_UPDATE_URL = `${PREFIX}proctoring_settings/update`;
export const SETTINGS_SETUP_URL = `${PREFIX}settings/setup`;
export const STUDENTS_URL = `${PREFIX}students`;
export const STUDENTS_LIST_URL = `${PREFIX}students/list_lms`;
export const STUDENTS_GET_STUDENT_BY_ID_URL = `${PREFIX}students/get_student_by_id`;
export const SETTINGS_IMPORT_STUDENTS_URL = `${PREFIX}settings/import_students`;
export const COURSES_URL = `${PREFIX}courses`;
export const COURSES_LIST_URL = `${PREFIX}courses/list`;
export const COURSES_LMS_LIST_URL = `${PREFIX}courses/list_lms`;
export const COURSE_BY_ID_URL = `${PREFIX}courses/get_course_by_id`;
export const DASHBOARD_SESSIONS_COUNT_WIDGET_URL = `${PREFIX}dashboard/e_proctoring_sessions_count_widget`;
export const DASHBOARD_DAILY_SESSIONS_WIDGET_URL = `${PREFIX}dashboard/e_proctoring_sessions_analysis_widget`;
export const DASHBOARD_INCIDENT_LEVEL_ANALYTICS_WIDGET_URL = `${PREFIX}dashboard/e_proctoring_incidents_widget`;
export const DASHBOARD_EVENTS_ANALYTICS_WIDGET_URL = `${PREFIX}dashboard/e_proctoring_events_widget`;
export const DASHBOARD_WIDGETS_URL = `${PREFIX}dashboard/get`;
export const DASHBOARD_WIDGETS_UPDATE_URL = `${PREFIX}dashboard/update`;
export const ACCOMMODATIONS_STUDENTS_LIST_URL = `${PREFIX}accommodations/students`;
export const ACCOMMODATIONS_LIST_URL = `${PREFIX}accommodations/list`;
export const ACCOMMODATIONS_LIST_BY_STUDENT_URL = `${PREFIX}accommodations/get_accommodation_by_student`;
export const ACCOMMODATIONS_DELETE_URL = `${PREFIX}accommodations/delete`;
export const ACCOMMODATIONS_CREATE_URL = `${PREFIX}accommodations/create`;
export const ACCOMMODATIONS_UPDATE_URL = `${PREFIX}accommodations/update`;
export const STUDENT_GUIDELINES_URL = `${PREFIX}student_guidelines/get_student_guideline`;
export const STUDENT_GUIDELINES_UPDATE_URL = `${PREFIX}student_guidelines/update`;
export const SUBMISSIONS_UPDATE_URL = `${PREFIX}submissions/update`;
export const SUBMISSIONS_URL = `${PREFIX}submissions`;
export const SUBMISSIONS_LIST_URL = `${PREFIX}submissions/list`;
export const SUBMISSION_BY_ID_URL = `${PREFIX}submissions/get_submission_by_id`;
export const SUBMISSIONS_SESSIONS_LIST_URL = `${PREFIX}sessions/list`;
export const SUBMISSIONS_SESSIONS_DETAIL_URL = `${PREFIX}sessions/detail`;
export const SUBMISSIONS_SESSIONS_UPDATE_URL = `${PREFIX}sessions/update`;
export const THIRD_PARTY_QUIZZES_LIST_URL = `${PREFIX}quizzes/list_third_party`;
export const QUIZZES_CREATE_URL = `${PREFIX}quizzes/create`;
export const QUIZZES_DELETE_URL = `${PREFIX}quizzes/delete`;
export const JOB_QUIZZES_URL = `${PREFIX}quizzes/status_job`;
export const SIGN_THIRD_PARTY_QUIZ_TOKEN_URL = "/api/third-party-quizzes/sign";
export const SUBMISSION_DETECTORS_BY_SUBMISSION_ID_URL = `${PREFIX}submissions/get_submission_detector_by_submission_id`;
export const SUBMISSION_DETECTOR_BY_ID_URL = `${PREFIX}submissions/get_submission_detector_by_id`;
export const DASHBOARD_AI_DETECTOR_ANALYTICS_WIDGET_URL = `${PREFIX}dashboard/ai_detector_analytics_widget`;
export const GET_LICENSE_URL = `${PREFIX}settings/get_licenses`;
export const GET_SAVE_LICENSE_JOB_STATUS_URL = `${PREFIX}settings/save_license_job_status`;
export const SAVE_LICENSE_URL = `${PREFIX}settings/save_license`;
export const ACTIVE_COURSES_URL = `${PREFIX}courses/active`;
export const ACTIVE_ALL_COURSES_URL = `${PREFIX}courses/active_all`;
export const ACCOUNTS_LIST_URL = `${PREFIX}accounts/list`;
export const SAVE_CURRENT_ACCOUNT_URL = `${PREFIX}accounts/account_switch`;
export const ACCOUNTS_ENABLED_URL = `${PREFIX}settings/accounts_enabled`;
