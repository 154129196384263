import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

interface IHeaderProps {
  size: "small" | "large";
}

export const Header: ComponentType<
  HTMLAttributes<HTMLDivElement> & IHeaderProps
> = styled("div")<IHeaderProps>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  h6 {
    ${(props) => props.theme.customTypography.small};
    color: ${(props) => props.theme.customColors.common.text};
    span {
      color: ${(props) => props.theme.customColors.gray[500]};
    }
  }

  p {
    ${(props) => props.theme.customTypography.default};
    font-size: 1rem;
    font-weight: bold;
    color: ${(props) => props.theme.customColors.primary[500]};
  }
`;

export const Icon: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  margin-right: 1rem;
`;
