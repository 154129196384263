/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
import { useState, useMemo, useEffect } from "react";
import {
  FormHelperText,
  Grid,
  useMediaQuery as useMUIMediaQuery,
  useTheme,
} from "@mui/material";

import { Menu } from "./Menu";
import {
  formatCurrentFilters,
  formatOptionsWithDates,
  getDatesObjectByFilterName,
  TDefaultFilters,
  TFilterOption,
} from "./Filters.utils";

import * as S from "./Filters.styles";
import {
  Input,
  Button,
  ButtonDropdown,
  RangeDatePickerModal,
} from "@edusynch/edusynch-ui";
import { ConfirmTooltip, ResponsiveFiltersModal } from "src/components/common";
import {
  convertUTCDateToUserTimezone,
  formatDate,
} from "@edusynch/edusynch-ui";
import { FiltersPlaceholder } from "./Filters.placeholder";
import { isFalsy } from "@/utils";
import { Icon } from "../Icon";
import { useTranslation } from "next-i18next";

const TRANSLATION_PREFIX = "filters";

interface IFiltersProps {
  options?: TFilterOption[];
  defaultSearch: string;
  defaultFilters?: TDefaultFilters[];
  defaultDates?: any;
  isDateTime?: boolean;
  onFilterChange?: (filter: any) => void;
  onClearAllFilters: () => void;
  onClearFilter: (filter: any) => void;
  onFilter: (filters: any) => void;
  loading?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  timezone?: string;
  isResponsiveFiltersModalOpen: boolean;
  onCloseResponsiveFiltersModal: () => void;
  minSearchLength?: number;
  isMenuOptionsLoading?: boolean;
  onMenuSearchChange?: (term: string) => void;
}

function Filters({
  options = [],
  defaultSearch,
  defaultFilters,
  defaultDates,
  isDateTime,
  onFilterChange,
  onClearAllFilters,
  onClearFilter,
  onFilter,
  loading,
  dateFormat = "MM/dd/yyyy",
  timeFormat = "24",
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  isResponsiveFiltersModalOpen,
  onCloseResponsiveFiltersModal,
  minSearchLength,
  isMenuOptionsLoading,
  onMenuSearchChange,
}: IFiltersProps) {
  const [shouldCloseDropdown, setShouldCloseDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [dates, setDates] = useState({
    start_date: defaultDates?.start_date,
    end_date: defaultDates?.end_date,
    start_evaluated_date: defaultDates?.start_evaluated_date,
    end_evaluated_date: defaultDates?.end_evaluated_date,
    start_schedule_date: defaultDates?.start_schedule_date,
    end_schedule_date: defaultDates?.end_schedule_date,
    start_submission_date: defaultDates?.start_submission_date,
    end_submission_date: defaultDates?.end_submission_date,
  });

  const [isRangeDatePickerModalOpen, setIsRangeDatePickerModalOpen] =
    useState(false);

  const [
    isRangeEvaluateDatePickerModalOpen,
    setIsRangeEvaluateDatePickerModalOpen,
  ] = useState(false);

  const [
    isRangeScheduleDatePickerModalOpen,
    setIsRangeScheduleDatePickerModalOpen,
  ] = useState(false);

  const [
    isRangeSubmissionDatePickerModalOpen,
    setIsRangeSubmissionDatePickerModalOpen,
  ] = useState(false);

  const theme = useTheme();
  const isMobile = useMUIMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useTranslation("common");

  useEffect(() => {
    setSearch(defaultSearch || "");
  }, [defaultSearch]);

  useEffect(() => {
    if (defaultDates) {
      setDates({
        start_date: defaultDates.start_date,
        end_date: defaultDates.end_date,
        start_evaluated_date: defaultDates?.start_evaluated_date,
        end_evaluated_date: defaultDates?.end_evaluated_date,
        start_schedule_date: defaultDates?.start_schedule_date,
        end_schedule_date: defaultDates?.end_schedule_date,
        start_submission_date: defaultDates?.start_submission_date,
        end_submission_date: defaultDates?.end_submission_date,
      });
    }
  }, [defaultDates]);

  const hasDate = useMemo(() => {
    return (
      defaultDates && "start_date" in defaultDates && "end_date" in defaultDates
    );
  }, [defaultDates]);

  const hasEvaluateDate = useMemo(() => {
    return (
      defaultDates &&
      "start_evaluated_date" in defaultDates &&
      "end_evaluated_date" in defaultDates
    );
  }, [defaultDates]);

  const hasScheduleDate = useMemo(() => {
    return (
      defaultDates &&
      "start_schedule_date" in defaultDates &&
      "end_schedule_date" in defaultDates
    );
  }, [defaultDates]);

  const hasSubmissionDate = useMemo(() => {
    return (
      defaultDates &&
      "start_submission_date" in defaultDates &&
      "end_submission_date" in defaultDates
    );
  }, [defaultDates]);

  const formattedCurrentFilters = formatCurrentFilters(options, defaultFilters);
  const formattedOptions = defaultDates
    ? formatOptionsWithDates({
        options,
        hasDate,
        hasEvaluateDate,
        hasScheduleDate,
        hasSubmissionDate,
        t,
      })
    : options;

  const hasSelectedFilters = useMemo(() => {
    return (
      !!defaultSearch ||
      !isFalsy(formattedCurrentFilters) ||
      !isFalsy(defaultDates)
    );
  }, [defaultSearch, formattedCurrentFilters, defaultDates]);

  const handleSubmitFilters = () => {
    onFilter({
      search: search.trim(),
      start_date: dates.start_date,
      end_date: dates.end_date,
      start_evaluated_date: dates?.start_evaluated_date,
      end_evaluated_date: dates?.end_evaluated_date,
      start_schedule_date: dates?.start_schedule_date,
      end_schedule_date: dates?.end_schedule_date,
      start_submission_date: dates?.start_submission_date,
      end_submission_date: dates?.end_submission_date,
    });
  };

  const handleSearchKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (!!minSearchLength && searchLengthNotMet) return;

      return handleSubmitFilters();
    }
  };

  const handleFilterChange = (filter: any) => {
    const isDateFilter = filter?.type.includes("Date Interval");
    const isEvaluateDateFilter = filter?.type.includes("Evaluated Date");
    const isScheduleDateFilter = filter?.type.includes("Scheduled Date");
    const isSubmissionDateFilter = filter?.type.includes("Submission Date");

    if (
      !isDateFilter &&
      !isEvaluateDateFilter &&
      !isScheduleDateFilter &&
      !isSubmissionDateFilter &&
      onFilterChange
    ) {
      onFilterChange(filter);
      return;
    }

    const { filterName } = filter;

    const datesObject = getDatesObjectByFilterName(filterName);

    if (datesObject !== "custom") {
      if (isDateFilter) {
        setDates((prev) => ({ ...prev, ...datesObject }));
      } else if (isEvaluateDateFilter) {
        setDates((prev) => ({
          ...prev,
          start_evaluated_date: datesObject?.start_date,
          end_evaluated_date: datesObject?.end_date,
        }));
      } else if (isScheduleDateFilter) {
        setDates((prev) => ({
          ...prev,
          start_schedule_date: datesObject?.start_date,
          end_schedule_date: datesObject?.end_date,
        }));
      } else if (isSubmissionDateFilter) {
        setDates((prev) => ({
          ...prev,
          start_submission_date: datesObject?.start_date,
          end_submission_date: datesObject?.end_date,
        }));
      }
    } else if (isDateFilter) {
      setIsRangeDatePickerModalOpen(true);
    } else if (isEvaluateDateFilter) {
      setIsRangeEvaluateDatePickerModalOpen(true);
    } else if (isScheduleDateFilter) {
      setIsRangeScheduleDatePickerModalOpen(true);
    } else if (isSubmissionDateFilter) {
      setIsRangeSubmissionDatePickerModalOpen(true);
    }
  };

  const handleClearSearch = () => {
    onFilter({
      search: "",
      start_date: dates.start_date,
      end_date: dates.end_date,
      start_evaluated_date: dates.start_evaluated_date,
      end_evaluated_date: dates.end_evaluated_date,
      start_schedule_date: dates.start_schedule_date,
      end_schedule_date: dates.end_schedule_date,
      start_submission_date: dates.start_submission_date,
      end_submission_date: dates.end_submission_date,
    });
  };

  const requiredFiltersNotSelected = defaultFilters
    ?.filter((filter) => filter.required)
    ?.some((filter) => !filter.value);

  const hasTwoOrMoreDateFilters =
    Object?.values(dates)?.filter((date) => Boolean(date)).length > 2;

  const searchLengthNotMet =
    !!search && search.length < Number(minSearchLength);

  const handleMenuSearch = (searchTerm: string) => {
    if (!onMenuSearchChange) return;

    onMenuSearchChange(searchTerm);
  };

  if (isMobile) {
    return (
      <>
        <ResponsiveFiltersModal
          isOpen={isResponsiveFiltersModalOpen}
          onClose={onCloseResponsiveFiltersModal}
          options={formattedOptions}
          defaultFilters={defaultFilters}
          currentDates={dates}
          dateFormat={dateFormat}
          isDateTime={isDateTime}
          timeFormat={timeFormat}
          timezone={timezone}
          defaultSearch={defaultSearch}
          search={search}
          setSearch={setSearch}
          hasSelectedFilters={hasSelectedFilters}
          onClearFilter={onClearFilter}
          onClearSearch={handleClearSearch}
          onFilterSelected={handleFilterChange}
          onSave={handleSubmitFilters}
          onClearAll={onClearAllFilters}
        />
        <RangeDatePickerModal
          open={isRangeDatePickerModalOpen}
          onClose={() => setIsRangeDatePickerModalOpen(false)}
          startDate={dates?.start_date}
          endDate={dates?.end_date}
          onDateChange={(date) => setDates((prev) => ({ ...prev, ...date }))}
          onSave={() => setIsRangeDatePickerModalOpen(false)}
          isDateTime={isDateTime}
          customTitle={
            isDateTime ? t(`${TRANSLATION_PREFIX}.date_and_hour_interval`) : ""
          }
          calendarIcon={<Icon name="calendar" width={16} height={16} />}
          changeIcon={<Icon name="change" width={16} height={16} />}
          closeIcon={<Icon name="close" size="small" variant="gray" />}
        />
      </>
    );
  }

  return (
    <S.Container data-testid="filters-component">
      <RangeDatePickerModal
        open={isRangeDatePickerModalOpen}
        onClose={() => setIsRangeDatePickerModalOpen(false)}
        startDate={dates?.start_date}
        endDate={dates?.end_date}
        onDateChange={(date) => setDates((prev) => ({ ...prev, ...date }))}
        onSave={() => setIsRangeDatePickerModalOpen(false)}
        isDateTime={isDateTime}
        customTitle={
          isDateTime ? t(`${TRANSLATION_PREFIX}.date_and_hour_interval`) : ""
        }
        calendarIcon={<Icon name="calendar" width={16} height={16} />}
        changeIcon={<Icon name="change" width={16} height={16} />}
        closeIcon={<Icon name="close" size="small" variant="gray" />}
      />
      <RangeDatePickerModal
        open={isRangeEvaluateDatePickerModalOpen}
        onClose={() => setIsRangeEvaluateDatePickerModalOpen(false)}
        startDate={dates?.start_evaluated_date}
        endDate={dates?.end_evaluated_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_evaluated_date:
              date?.start_date || dates?.start_evaluated_date,
            end_evaluated_date: date?.end_date || dates?.end_evaluated_date,
          }));
        }}
        onSave={() => setIsRangeEvaluateDatePickerModalOpen(false)}
        customTitle={t(`${TRANSLATION_PREFIX}.evaluated_date`)}
        calendarIcon={<Icon name="calendar" width={16} height={16} />}
        changeIcon={<Icon name="change" width={16} height={16} />}
        closeIcon={<Icon name="close" size="small" variant="gray" />}
      />
      <RangeDatePickerModal
        open={isRangeScheduleDatePickerModalOpen}
        onClose={() => setIsRangeScheduleDatePickerModalOpen(false)}
        startDate={dates?.start_schedule_date}
        endDate={dates?.end_schedule_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_schedule_date: date?.start_date || dates?.start_schedule_date,
            end_schedule_date: date?.end_date || dates?.end_schedule_date,
          }));
        }}
        onSave={() => setIsRangeScheduleDatePickerModalOpen(false)}
        customTitle={t(`${TRANSLATION_PREFIX}.scheduled_date`)}
        calendarIcon={<Icon name="calendar" width={16} height={16} />}
        changeIcon={<Icon name="change" width={16} height={16} />}
        closeIcon={<Icon name="close" size="small" variant="gray" />}
      />
      <RangeDatePickerModal
        open={isRangeSubmissionDatePickerModalOpen}
        onClose={() => setIsRangeSubmissionDatePickerModalOpen(false)}
        startDate={dates?.start_submission_date}
        endDate={dates?.end_submission_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_submission_date:
              date?.start_date || dates?.start_submission_date,
            end_submission_date: date?.end_date || dates?.end_submission_date,
          }));
        }}
        onSave={() => setIsRangeSubmissionDatePickerModalOpen(false)}
        customTitle={t(`${TRANSLATION_PREFIX}.submission_date`)}
        calendarIcon={<Icon name="calendar" width={16} height={16} />}
        changeIcon={<Icon name="change" width={16} height={16} />}
        closeIcon={<Icon name="close" size="small" variant="gray" />}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{
                marginBottom: isMobile ? "1rem" : "0",
              }}
            >
              <Input
                startIcon={<Icon name="search" width={16} height={16} />}
                size="small"
                fullWidth
                rounded
                placeholder={t(`${TRANSLATION_PREFIX}.search`)}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                onKeyUp={handleSearchKeyUp}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "2rem",
                }}
              />
              {!!minSearchLength && searchLengthNotMet && (
                <FormHelperText>
                  {t(`${TRANSLATION_PREFIX}.search_length_helper`)}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  md
                  style={{ paddingLeft: isMobile ? "0.5rem" : "0" }}
                >
                  {!!formattedOptions.length && (
                    <ButtonDropdown
                      startIcon={
                        <Icon
                          name="custom"
                          width={12}
                          height={12}
                          variant="primary"
                        />
                      }
                      title={t(`${TRANSLATION_PREFIX}.filter`)}
                      autoClose={shouldCloseDropdown}
                      onClose={() => setShouldCloseDropdown(false)}
                      buttonStyles={{ padding: "1rem" }}
                    >
                      <Menu
                        options={formattedOptions}
                        currentFilters={defaultFilters}
                        onFilterChange={handleFilterChange}
                        onSelect={() => setShouldCloseDropdown(true)}
                        onSearchMouseEnter={() => setShouldCloseDropdown(false)}
                        onSearchMouseLeave={() => setShouldCloseDropdown(true)}
                        onHeaderMouseEnter={() => setShouldCloseDropdown(false)}
                        onHeaderMouseLeave={() => setShouldCloseDropdown(true)}
                        isLoading={isMenuOptionsLoading}
                        onSearchChange={handleMenuSearch}
                      />
                    </ButtonDropdown>
                  )}
                </Grid>
                <Grid item xs={8} md>
                  <Grid container justifyContent="end">
                    {hasSelectedFilters && (
                      <ConfirmTooltip
                        subtitle={t(
                          `${TRANSLATION_PREFIX}.clear_filters_warning`
                        )}
                        onConfirm={onClearAllFilters}
                      >
                        <Button
                          variant="text"
                          size="medium"
                          data-testid="clear-all-button"
                        >
                          {t(`${TRANSLATION_PREFIX}.clear_all`)}
                        </Button>
                      </ConfirmTooltip>
                    )}
                    <Button
                      size="medium"
                      onClick={handleSubmitFilters}
                      disabled={
                        requiredFiltersNotSelected || searchLengthNotMet
                      }
                      type="button"
                      data-testid="search-button"
                    >
                      {t(`${TRANSLATION_PREFIX}.search`)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {hasSelectedFilters && (
        <>
          <S.Separator />
          <S.SelectedFilters>
            {!!defaultSearch && (
              <S.SearchContainer
                style={{
                  marginBottom:
                    isMobile &&
                    (!isFalsy(formattedCurrentFilters) ||
                      !isFalsy(defaultDates))
                      ? "1rem"
                      : "0",
                }}
              >
                <S.Label>Search</S.Label>
                <S.SearchValue>
                  {defaultSearch}
                  <ConfirmTooltip
                    subtitle={t(`${TRANSLATION_PREFIX}.clear_filter_warning`)}
                    onConfirm={handleClearSearch}
                  >
                    <Icon name="close" variant="white" />
                  </ConfirmTooltip>
                </S.SearchValue>
              </S.SearchContainer>
            )}
            {loading ? (
              <FiltersPlaceholder filters={formattedCurrentFilters?.length} />
            ) : (
              <>
                {(!isFalsy(formattedCurrentFilters) ||
                  !isFalsy(defaultDates)) && (
                  <S.FiltersContainer>
                    <Grid container style={{ margin: "0", flexWrap: "nowrap" }}>
                      <S.Label
                        style={{ paddingTop: "0.5rem" }}
                        data-testid="filter-label"
                      >
                        Filter
                      </S.Label>
                      <S.Filters>
                        {formattedCurrentFilters?.map((filter) => (
                          <S.FilterValue key={filter.id}>
                            <span>{filter.type}:</span> {filter.label}
                            {!filter.required && (
                              <ConfirmTooltip
                                subtitle={t(
                                  `${TRANSLATION_PREFIX}.clear_filter_warning`
                                )}
                                onConfirm={() => onClearFilter(filter)}
                              >
                                <Icon name="close" variant="white" />
                              </ConfirmTooltip>
                            )}
                          </S.FilterValue>
                        ))}
                        {!isFalsy(defaultDates) &&
                          (dates.start_date || dates.end_date) && (
                            <S.FilterValue
                              onClick={() =>
                                setIsRangeDatePickerModalOpen(true)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <S.FilterValueContent>
                                <span>{t(`${TRANSLATION_PREFIX}.date`)}</span>
                                {dates.start_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates.start_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                                &nbsp;
                                <span>
                                  {t(`${TRANSLATION_PREFIX}.to`)}
                                </span>{" "}
                                {dates.end_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates.end_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                              </S.FilterValueContent>
                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={t(
                                    `${TRANSLATION_PREFIX}.clear_filter_warning`
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_date: "",
                                      end_date: "",
                                    }))
                                  }
                                >
                                  <Icon name="close" variant="white" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(defaultDates) &&
                          (dates?.start_evaluated_date ||
                            dates?.end_evaluated_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeEvaluateDatePickerModalOpen(true)
                                }
                              >
                                <span>Evaluated Date:</span>
                                {dates?.start_evaluated_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.start_evaluated_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                                &nbsp;
                                <span>to</span>{" "}
                                {dates?.end_evaluated_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.end_evaluated_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={t(
                                    `${TRANSLATION_PREFIX}.clear_filter_warning`
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_evaluated_date: "",
                                      end_evaluated_date: "",
                                    }))
                                  }
                                >
                                  <Icon name="close" size="extra-small" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(defaultDates) &&
                          (dates?.start_schedule_date ||
                            dates?.end_schedule_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeScheduleDatePickerModalOpen(true)
                                }
                              >
                                <span>Scheduled Date:</span>
                                {dates?.start_schedule_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.start_schedule_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                                &nbsp;
                                <span>to</span>{" "}
                                {dates?.end_schedule_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.end_schedule_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={t(
                                    `${TRANSLATION_PREFIX}.clear_filter_warning`
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_schedule_date: "",
                                      end_schedule_date: "",
                                    }))
                                  }
                                >
                                  <Icon name="close" size="extra-small" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(defaultDates) &&
                          (dates?.start_submission_date ||
                            dates?.end_submission_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeSubmissionDatePickerModalOpen(true)
                                }
                              >
                                <span>Submission Date:</span>
                                {dates?.start_submission_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.start_submission_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                                &nbsp;
                                <span>to</span>{" "}
                                {dates?.end_submission_date &&
                                  formatDate({
                                    date: convertUTCDateToUserTimezone({
                                      date: dates?.end_submission_date,
                                      timezone,
                                    }),
                                    format: `${dateFormat} ${
                                      !isDateTime ? "" : timeFormat
                                    }`,
                                  })}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={t(
                                    `${TRANSLATION_PREFIX}.clear_filter_warning`
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_submission_date: "",
                                      end_submission_date: "",
                                    }))
                                  }
                                >
                                  <Icon name="close" size="extra-small" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {formattedOptions?.length > 1 && (
                          <ButtonDropdown
                            icon={
                              <Icon
                                name="plus"
                                width={16}
                                height={16}
                                variant="gray"
                              />
                            }
                            iconOnly
                            autoClose={false}
                          >
                            <Menu
                              options={formattedOptions}
                              currentFilters={defaultFilters}
                              onFilterChange={handleFilterChange}
                            />
                          </ButtonDropdown>
                        )}
                      </S.Filters>
                    </Grid>
                  </S.FiltersContainer>
                )}
              </>
            )}
          </S.SelectedFilters>
        </>
      )}
    </S.Container>
  );
}

export default Filters;
