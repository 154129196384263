import { DASHBOARD_SESSIONS_COUNT_WIDGET_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface UseSessionsCountWidgetProps {
  isMock?: boolean;
}

type GetSessionsCountWidgetResponse = {
  totalToday: number;
  totalWeek: number;
  totalMonth: number;
  averageTime: number;
};

const MOCKED_DATA = {
  totalToday: 320,
  totalWeek: 1230,
  totalMonth: 3123,
  averageTime: 3600,
};

export function useSessionsCountWidget({
  isMock,
}: UseSessionsCountWidgetProps) {
  const { api } = useApiClient();

  const getSessionsCountWidget =
    async (): Promise<GetSessionsCountWidgetResponse> => {
      const response = await api.get(DASHBOARD_SESSIONS_COUNT_WIDGET_URL);

      return {
        totalToday: response.data.content.total_today,
        totalWeek: response.data.content.total_week,
        totalMonth: response.data.content.total_month,
        averageTime: response.data.content.average_time,
      };
    };

  const getSessionsCountWidgetMockData = async () => {
    return MOCKED_DATA;
  };

  return useQuery({
    queryKey: ["sessions_count_widget", isMock],
    queryFn: isMock ? getSessionsCountWidgetMockData : getSessionsCountWidget,
    staleTime: 1000 * 60 * 1,
  });
}
